// Generated by Framer (1f5f6d9)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const serializationHash = "framer-ePhTX"

const variantClassNames = {ufL8KgpT5: "framer-v-1a1ra1n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ufL8KgpT5", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1a1ra1n", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ufL8KgpT5"} ref={refBinding} style={{backgroundColor: "rgb(111, 92, 250)", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "10px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Scale your sales</motion.p></React.Fragment>} className={"framer-1wax6px"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"DNjLRTgvN"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-jpz0ol-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"GMUPpEVYI-container"} nodeId={"GMUPpEVYI"} rendersWithMotion scopeId={"jsLoiv6CP"}><Feather color={"rgb(102, 102, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"arrow-up-left"} id={"GMUPpEVYI"} layoutId={"GMUPpEVYI"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ePhTX.framer-nec4za, .framer-ePhTX .framer-nec4za { display: block; }", ".framer-ePhTX.framer-1a1ra1n { height: 24px; position: relative; width: 100px; }", ".framer-ePhTX .framer-1wax6px { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-ePhTX .framer-jpz0ol-container { flex: none; height: 22px; left: 0px; position: absolute; top: -20px; width: 19px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjsLoiv6CP: React.ComponentType<Props> = withCSS(Component, css, "framer-ePhTX") as typeof Component;
export default FramerjsLoiv6CP;

FramerjsLoiv6CP.displayName = "SCROLL";

FramerjsLoiv6CP.defaultProps = {height: 24, width: 100};

addFonts(FramerjsLoiv6CP, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...FeatherFonts], {supportsExplicitInterCodegen: true})